import * as React from 'react';
import translation from 'intl';
import { graphql, HeadFC } from 'gatsby';
import NewLayout from 'components/NewLayout';
import { withIntl } from 'LanguageProvider/withLanguageProvider';
import ProductFabrication from 'components/pages/productFabrication';
import SEOGraphQL from 'components/SEOGraphQL';
import { PageSEOType } from 'components/types';
import { PREFIX_CANONICAL_URL } from 'components/constant';

const pageUrl = `${PREFIX_CANONICAL_URL}/fabrication-management-system`;

const IndexPage = () => {
  return (
    <NewLayout headerStyle="transparent">
      <ProductFabrication pageUrl={pageUrl} />
    </NewLayout>
  );
};
export default withIntl(translation)(IndexPage);

export const Head = ({
  data,
}: {
  data: {
    strapiProductSpecificPage: PageSEOType;
  };
}) => {
  return (
    <SEOGraphQL data={data.strapiProductSpecificPage} canonicalURL={pageUrl} />
  );
};

export const query = graphql`
  query {
    strapiProductSpecificPage(
      ONLY_FOR_DEV_pageId: { eq: "productFabrication" }
    ) {
      seo {
        preventIndexing
      }
      SEO {
        metaDescription
        metaTitle
        metaSocial {
          title
          description
          image {
            data {
              attributes {
                url
              }
            }
          }
        }
      }
    }
  }
`;
